<template>
  <div class="edit-username-container">
    <form id="forgotPasswordForm">
      <div class="form-group">
        <label
          for="username"
          class="ml-0"
        >{{ $t("editUsername") }}</label>
        <input
          id="username"
          v-model="username"
          type="text"
          class="form-control"
          name="username"
          data-vv-as="New username"
        >
        <span
          v-if="errors.has('username')"
          class="error"
        >{{ errors.first('username') }}</span>
      </div>
      <div class="form-group pt-5">
        <button
          :disabled="errors.any() || submitting"
          type="submit"
          class="btn btn-primary"
          @click.prevent="changeUsername"
        >
          {{ $t("change") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
// import Vue from 'vue'
import { mapGetters } from 'vuex'
import { ErrorsDictionary } from '@/mixins/ErrorsDictionary'
export default {
  name: 'EditUsername',
  mixins: [ErrorsDictionary],
  props: ['isClinician'],
  data () {
    return {
      submitting: false,
      username: ''
    }
  },
  computed: mapGetters({
    user: 'loggedInUser'
  }),
  created () {
    this.username = this.user.username
  },
  mounted () {
    this.$validator.localize('en', this.errorsDictionary)
  },
  methods: {
    changeUsername () {
      // Same username, do nothing.
      if (this.user.username === this.username) {
        return false
      }

      this.submitting = true
      const loader = this.$loading.show()
      const data = { id: this.user.id, username: this.username }

      this.$store.dispatch('MY_ACCOUNT_UPDATE', data).then(response => {
        if (response && response.status !== 200) {
          const error = response.data.errors && response.data.errors.username ? response.data.errors.username[0] : null
          return this.$validator.errors.add('username', error)
        }
        this.$toast.success({ message: this.$t('changedSuccessfully', { field: 'Username' }) })
        if (!this.isClinician) {
          this.$router.push({ name: 'MyAccount' })
        } else {
          this.$emit('overlayGoBack')
        }
      }).catch(error => {
        this.$handleApiError(error)
        console.log(error) // eslint-disable-line no-console
      }).finally(() => {
        this.submitting = false
        loader.hide()
      })
    }
  }
}
</script>
